html,
body,
#root {
  height: 100%;
}

.fl {
  float: left;
}

.fr {
  float: right;
}

.white-space-nowrap {
  white-space: nowrap;
}

/* wysiwyg */
.rdw-dropdown-selectedtext {
  color: black;
  text-decoration: none;
}

/* ckeditor */
.ck-editor__editable {
  min-height: 200px;
}

.fs-7 {
  font-size: 0.8rem;
}

.fs-8 {
  font-size: 0.6rem;
}

.bg-gray {
  background-color: #eee;
}

.rounded-4 {
  border-radius: 1rem;
}

.noresize {
  resize: none;
}
